import { Component, OnInit } from '@angular/core';
import { FortressPropertyService } from '../../services/fortress-property.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-explore-our-properties',
  templateUrl: './explore-our-properties.component.html',
  styleUrls: ['./explore-our-properties.component.scss'],
})
export class ExploreOurPropertiesComponent implements OnInit {
  properties = [
    {
      proptype: 'Retail',
      proptypeURL: 'Retail',
      desc: 'Our retail portfolio focuses on the time-sensitive commuter who requires a modern, safe and convenient offering. We are located mainly in the non-metropolitan areas.',
      id: '1',
      image: "url('../../../assets/images/portfolioRetail.webp')",
      icon: '../../../assets/icons/property-features/icon-retail.svg',
    },
    {
      proptype: 'Logistics',
      proptypeURL: 'Logistics',
      desc: 'Most of the logistics portfolio are in secure state-of-the-art logistics parks in prime and convenient locations which we developed either as a pre-let or a speculative warehouse to provide our tenants with best-in-class facilities.',
      id: '2',
      image: "url('../../../assets/images/portfolioLogistcs.webp')",
      icon: '../../../assets/icons/property-features/icon-logistics.svg',
    },
    {
      proptype: 'Office',
      proptypeURL: 'Office',
      desc: 'Comprising less than 5% of our total portfolio, we have offices in excellent locations in sought-after urban areas.',
      id: '3',
      image: "url('../../../assets/images/latest-article.webp')",
      icon: '../../../assets/icons/property-features/icon-offices.svg',
    },
    {
      proptype: 'Industrial',
      proptypeURL: 'Industrial',
      desc: 'Subscribe to receive our latest real-time vacancy and For Sale newsletter with easy-to-use search capabilities and detailed overview of current vacancies across all of our properties.',
      id: '4',
      image: "url('../../../assets/images/latest-video.webp')",
      icon: '../../../assets/icons/property-features/icon-industrial.svg',
    },
    {
      proptype: 'CEE Logistics',
      proptypeURL: 'CEELogistics',
      desc: 'Subscribe to receive our latest real-time vacancy and For Sale newsletter with easy-to-use search capabilities and detailed overview of current vacancies across all of our properties.',
      id: '5',
      image: "url('../../../assets/images/portfolioLogistcs.webp')",
      icon: '../../../assets/icons/property-features/icon-logistics.svg',
    },
  ];
  constructor(private property: FortressPropertyService) {}

  ngOnInit(): void {}
}
