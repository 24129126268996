<div class="container target" id="target" *ngIf="allContain">
    <div class="filter-col">
        <h2>Investor Documents</h2>
        <p>With a wealth of expertise and experience, and an unwavering commitment to powering growth, we aim to create long-term, sustainable value for shareholders.</p>
        
        <div class="category-menu" (click)="toggleCategory()">
            <p class="title">Categories</p>
            <img [ngClass]="{'open': showCategories}" src="../../../../../assets/icons/icon-arrow-black.svg" alt=""/>
        </div>
        <div class="categoryHolder" *ngIf="showCategories">
            <p [class.active]="activeCat === i" class="categoryName" *ngFor="let category of categories; let i = index" (click)="selectCategory(i)" >{{category.name}}</p>
        </div>
    </div>
    <div class="results-col" #scrollTargetElement>
        <div class="search-bar">
            <div class="col">
                <img src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                <input class="searchbox" type="text" placeholder="Search by keyword" [(ngModel)]="searchText"/>
            </div>
            <div class="col">
                <img src="../../../../../assets/icons/icon-filter.svg" alt=""/>
                <img (click)="clearFilter()" class="borderLeft" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
            </div>
        </div>
        <div class="all-container" >

            <div class="filterContainNew" *ngIf="showFilterDate">
                <p class="title">Sorted by year</p>
                <div class="filterRow">
                    <p [ngClass]="{'active' : date2025}" (click)="filterDate('2025')">2025</p>
                    <p [ngClass]="{'active' : date2024}" (click)="filterDate('2024')">2024</p>
                    <p [ngClass]="{'active' : date2023}" (click)="filterDate('2023')">2023</p>
                    <p [ngClass]="{'active' : date2022}" (click)="filterDate('2022')">2022</p>
                    <p [ngClass]="{'active' : date2021}" (click)="filterDate('2021')">2021</p>
                    <p [ngClass]="{'active' : date2020}" (click)="filterDate('2020')">2020</p>
                    <p [ngClass]="{'active' : date2019}" (click)="filterDate('2019')">2019</p>
                    <p [ngClass]="{'active' : date2018}" (click)="filterDate('2018')">2018</p>
                    <p [ngClass]="{'active' : date2017}" (click)="filterDate('2017')">2017</p>
                    <p [ngClass]="{'active' : date2016}" (click)="filterDate('2016')">2016</p>
                    <p [ngClass]="{'active' : date2015}" (click)="filterDate('2015')">2015</p>
                    <p [ngClass]="{'active' : date2014}" (click)="filterDate('2014')">2014</p>
                    <p [ngClass]="{'active' : date2013}" (click)="filterDate('2013')">2013</p>
                    <p [ngClass]="{'active' : date2012}" (click)="filterDate('2012')">2012</p>
                    <p [ngClass]="{'active' : date2011}" (click)="filterDate('2011')">2011</p>
                    <p [ngClass]="{'active' : date2010}" (click)="filterDate('2010')">2010</p>
                </div>
            </div>
            <h4 class="integratedHeading" *ngIf="showCombinedIntegratedDocs">Final</h4>
            <table class="dataTable">
                <thead>
                    <th class="firstCol" *ngIf="showFilterDate">Date</th>
                    <th>Title</th>
                    <th>Download</th>
                </thead>
                <tbody>
                    <tr *ngFor="let lesson of showDataArr  | searchFilter: searchText:dateSearch | paginate : {itemsPerPage: pageSize,  currentPage: page,totalItems: count};  let i = index">
                        <td  class="firstCol" *ngIf="showFilterDate" valign="center" class="dateTD">{{lesson.date}}</td>
                        <td valign="center" class="titleTD">{{lesson.title}}</td>
                        <td valign="center" class="downTD"><a class="clearLink" target="_new" [href]="getURL(lesson.media)"><img src="../../../../../assets/icons/document/download.svg" alt=""/></a></td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls
            *ngIf="!showCombinedIntegratedDocs"
            previousLabel="Prev"
            nextLabel="Next"
            [responsive]="true"
            (pageChange)="handlePageChange($event)"
        ></pagination-controls>

        <h4 class="integratedHeading" *ngIf="showCombinedIntegratedDocs">Interim</h4>
            <table class="dataTable" *ngIf="showCombinedIntegratedDocs">
                <thead>
                    <th class="firstCol" *ngIf="showFilterDate">Date</th>
                    <th>Title</th>
                    <th>Download</th>
                </thead>
                <tbody>
                    <tr *ngFor="let lesson of showDataArrIntegrated  | searchFilter: searchText:dateSearch | paginate : {itemsPerPage: pageSize,  currentPage: page,totalItems: count};  let i = index">
                        <td  class="firstCol" *ngIf="showFilterDate" valign="center" class="dateTD">{{lesson.date}}</td>
                        <td valign="center" class="titleTD">{{lesson.title}}</td>
                        <td valign="center" class="downTD"><a class="clearLink" target="_new" [href]="getURL(lesson.media)"><img src="../../../../../assets/icons/document/download.svg" alt=""/></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="webcasts"  *ngIf="!allContain">
            <app-webcasts></app-webcasts>
        </div>
    </div>
</div>
<div class="container target" id="target" *ngIf="!allContain">
    <div class="filter-col">
        <h2 >Investor Documents</h2>
        <p>With a wealth of expertise and experience, and an unwavering commitment to powering growth, we aim to create long-term, sustainable value for shareholders.</p>
        <div class="filter">
            <p class="title">sorted by year</p>
            <p (click)="toggleDate()" class="selectedOption">{{selectedDate}} <img [ngClass]="{'open': !showDates}" src="../../../../../assets/icons/icon-arrow-black.svg" alt=""/></p>
            <div class="categoryHolderDate" *ngIf="showDates">
                <p class="categoryName" *ngFor="let date of dateRange" (click)="selectDate1(date.name)">{{date.name}}</p>
            </div>
        </div>
        
        <div class="category-menu" (click)="toggleCategory()">
            <p class="title">Categories</p>
            <img [ngClass]="{'open': showCategories}" src="../../../../../assets/icons/icon-arrow-black.svg" alt=""/>
        </div>
        <div class="categoryHolder" *ngIf="showCategories">
            <p [class.active]="activeCat === i" class="categoryName" *ngFor="let category of categories; let i = index" (click)="selectCategory(i)" >{{category.name}}</p>
        </div>
    </div>
    <div class="results-col">
        <div class="search-bar">
            <div class="col">
                <img src="../../../../../assets/icons/icon-magnifier-black.svg" alt=""/>
                <input class="searchbox" type="text" placeholder="Search by keyword" [(ngModel)]="searchText"/>
            </div>
            <div class="col">
                <img src="../../../../../assets/icons/icon-filter.svg" alt=""/>
                <img (click)="clearFilter()" class="borderLeft" src="../../../../../assets/icons/icon-close-black.svg" alt=""/>
            </div>
        </div>
        <div class="webcasts"  *ngIf="!allContain">
            <app-webcasts [sort]="dateToSort"></app-webcasts>
        </div>
    </div>
</div>