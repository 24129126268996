import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { OurImpactComponent } from './modules/about/our-impact/our-impact.component';
import { OurHistoryComponent } from './modules/about/our-history/our-history.component';
import { MeetTheTeamComponent } from './modules/about/meet-the-team/meet-the-team.component';

import { ContactUsComponent } from './modules/contact/contact-us/contact-us.component';
import { OurDetailsComponent } from './modules/contact/our-details/our-details.component';
import { WhistleblowerComponent } from './modules/contact/whistleblower/whistleblower.component';

import { FoursightComponent } from './modules/foursight/foursight.component';
import { PressReleaseComponent } from './modules/foursight/press-release/press-release.component';
import { VideosComponent } from './modules/foursight/videos/videos.component';
import { EditionsComponent } from './modules/foursight/editions/editions.component';
import { NewDevelopmentsComponent } from './modules/properties/new-developments/new-developments.component';

import { InvestorDocumentsComponent } from './modules/investor-relations/investor-documents/investor-documents.component';
import { OverviewComponent } from './modules/investor-relations/overview/overview.component';

import { AuthGuardService } from './services/auth-guard.service';  
import { LoginComponent } from './core/login/login.component'; 
import {LoginDraftComponent} from './modules/investor-relations/login-draft/login-draft.component';
import {ArticleComponent} from './modules/investor-relations/components/article/article.component';
import { RegistrationComponent } from './core/registration/registration.component';

import { VacanciesComponent } from './modules/vacancies/vacancies.component';
import { ScheduleOfPropertiesComponent } from './modules/properties/schedule-of-properties/schedule-of-properties.component';
import {IndividualPropertyComponent} from './modules/properties/individual-property/individual-property.component';
import {IndividualPropertiesVacanciesComponent} from './modules/properties/individual-properties-vacancies/individual-properties-vacancies.component';
import {PropertyPortfolioComponent} from './modules/properties/property-portfolio/property-portfolio.component';

import {AllComponent} from './modules/foursight/all/all.component';

import {DraftsComponent} from './modules/investor-relations/drafts/drafts.component';
import {ViewPdfComponent} from './modules/investor-relations/view-pdf/view-pdf.component';
import {ShareholderAnalysisComponent} from './modules/investor-relations/shareholder-analysis/shareholder-analysis.component';
import {UpcomingEventsComponent} from './modules/investor-relations/upcoming-events/upcoming-events.component';
import {CorporateGovernanceComponent} from './modules/about/corporate-governance/corporate-governance.component';
import {CompanyPaiComponent} from './modules/about/company-pai/company-pai.component';
import {PrivacyComponent} from './modules/privacy/privacy.component';
import {DataAccessComponent} from './modules/data-access/data-access.component';

import {EventsComponent} from './modules/foursight/events/events.component';



import {SuccessComponent} from './modules/form/success/success.component';
import {FailureComponent} from './modules/form/failure/failure.component';

import {TestComponent} from './modules/form/test/test.component';

import { SusOurImpact } from './modules/sustainability/our-impact/our-impact.component';
import { SusSocial } from './modules/sustainability/social/social.component';
import { SponsorshipComponent } from './modules/foursight/sponsorship/sponsorship.component';




const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about/our-impact', component: OurImpactComponent },
  { path: 'about/about-us', component: OurHistoryComponent },
  { path: 'about/meet-the-team', component: MeetTheTeamComponent },
  { path: 'about/corporate-governance', component: CorporateGovernanceComponent },
  { path: 'about/paia', component: CompanyPaiComponent },
  { path: 'get-in-touch', component: ContactUsComponent },
  { path: 'our-details', component: OurDetailsComponent },
  { path: 'whistleblower', component: WhistleblowerComponent },
  { path: 'foursight', component: FoursightComponent, },
  { path: 'foursight/videos', component: VideosComponent },
  { path: 'foursight/press-release', component: PressReleaseComponent },
  { path: 'foursight/all-articles', component: AllComponent },
  // { path: 'foursight/sponsorships', component: SponsorshipComponent }, 
  { path: 'properties/new-developments', component: NewDevelopmentsComponent },
  { path: 'properties/schedule-of-properties', component: ScheduleOfPropertiesComponent },
  { path: 'investor-relations/investor-documents', component: InvestorDocumentsComponent },
  { path: 'investor-relations/overview', component: OverviewComponent },
  { path: 'vacancies',  component: VacanciesComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent }, 
  { path: 'login-financials', component: LoginDraftComponent }, 
  { path: 'register-vacancy-schedule', component: RegistrationComponent },
  { path: 'properties/indivual-property', component: IndividualPropertyComponent },   
  { path: 'properties/indivual-property-vacancies', component: IndividualPropertiesVacanciesComponent }, 
  { path: 'properties/property-portfolio', component: PropertyPortfolioComponent },   
  { path: 'financials/drafts', component: DraftsComponent, canActivate: [AuthGuardService] },   
  { path: 'investor-relations/shareholder-analysis', component: ShareholderAnalysisComponent,  }, 
  { path: 'investor-relations/upcoming-events', component: UpcomingEventsComponent,  }, 
  { path: 'foursight/press-release/article', component: ArticleComponent,  }, 
  { path: 'foursight/edition', component: EditionsComponent,  }, 
  { path: 'foursight/events', component: EventsComponent,  }, 
  { path: 'privacy-notice', component: PrivacyComponent,  }, 
  { path: 'data-access', component: DataAccessComponent,  },
  { path: 'form-submission-success', component: SuccessComponent,  },
  { path: 'form-submission-failed', component: FailureComponent,  },
  { path: 'sustainability/our-impact', component: SusOurImpact,  },
  { path: 'sustainability/social', component: SusSocial,  },
  { path: 'form-test', component: TestComponent,  },
  { path: 'financials/view-pdf', component: ViewPdfComponent,  },

  { path: 'corporate-governance', redirectTo:"about/corporate-governance", pathMatch: "full",  },
  { path: 'directors', redirectTo:"about/meet-the-team", pathMatch: "full",  },
  { path: 'pai', redirectTo:"about/paia", pathMatch: "full",  },
  { path: 'webcasts', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'videos', redirectTo:"foursight/videos", pathMatch: "full",  },
  { path: 'circulars', redirectTo:"foursight/press-release", pathMatch: "full",  },
  { path: 'bee', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'financials', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'dmtn-programme', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'capital-conversion', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'results-presentations', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'other-presentations', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'rights-offer', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'industrial-properties', redirectTo:"properties/property-portfolio", pathMatch: "full",  },
  { path: 'retail-properties', redirectTo:"properties/property-portfolio", pathMatch: "full",  },
  { path: 'logistics-properties', redirectTo:"properties/property-portfolio", pathMatch: "full",  },
  { path: 'office-properties', redirectTo:"properties/property-portfolio", pathMatch: "full",  },
  { path: 'eastport', redirectTo:"properties/property-portfolio", pathMatch: "full",  },
  { path: 'press-releases', redirectTo:"foursight/press-release", pathMatch: "full",  },
  { path: 'development-properties', redirectTo:"properties/new-developments", pathMatch: "full",  },
  { path: 'about-us', redirectTo:"about/about-us", pathMatch: "full",  },
  { path: 'investor-relations', redirectTo:"investor-relations/investor-documents", pathMatch: "full",  },
  { path: 'contact-us', redirectTo:"get-in-touch", pathMatch: "full",  },
  { path: 'shareholder-analysis', redirectTo:"investor-relations/shareholder-analysis", pathMatch: "full",  },
  { path: 'schedule-of-properties', redirectTo:"properties/schedule-of-properties", pathMatch: "full",  },
  { path: 'corporate-social-investment', redirectTo:"about/our-impact", pathMatch: "full",  },
  { path: '**', component: HomeComponent,  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', 
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64] // [x, y] - adjust scroll offset
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
