<div class="sub-menu">
  <div class="col">
    <a
      routerLinkActive="active-link"
      (click)="onclicked()"
      [routerLink]="['/foursight']"
      [routerLinkActiveOptions]="{ exact: true }"
      >Newsletters</a
    >
    <a
      routerLinkActive="active-link"
      (click)="onclicked()"
      [routerLink]="['/foursight/press-release']"
      [routerLinkActiveOptions]="{ exact: true }"
      >Articles and Insights</a
      
    >
    <!-- <a
      routerLinkActive="active-link"
      (click)="onclicked()"
      [routerLink]="['/foursight/videos']"
      [routerLinkActiveOptions]="{ exact: true }"
      >Thought Leadership Events</a
    > -->
    <a
      routerLinkActive="active-link"
      (click)="onclicked()"
      [routerLink]="['/foursight/videos']"
      [routerLinkActiveOptions]="{ exact: true }"
      >Videos</a
    >
    <!-- <a
      routerLinkActive="active-link"
      (click)="onclicked()"
      [routerLink]="['/foursight/sponsorships']"
      [routerLinkActiveOptions]="{ exact: true }"
      >Sponsorships</a
    > -->
    <!-- <a routerLinkActive="active-link" (click)="onclicked()" [routerLink]="['/foursight/events']" [routerLinkActiveOptions]="{exact:true}">Events</a> -->
  </div>
</div>
