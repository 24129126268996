<div  class="sub-menu">
    <div class="col line-right">
        <h5>Our Properties</h5>
        <a (click)="onclicked()" [routerLink]="['/properties/new-developments']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">New Developments</a>
        <a (click)="onclicked()" [routerLink]="['/properties/schedule-of-properties']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Schedule Of Properties</a>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">Our Property Portfolio</a>
    </div>
    <div class="col">
        <h5>Property type</h5>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}">All</a>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']"  routerLinkActive="active-link" [queryParams]="{ category:  'Logistics'}" [routerLinkActiveOptions]="{exact:true}">Logistics</a>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']"  routerLinkActive="active-link" [queryParams]="{ category:  'Office'}" [routerLinkActiveOptions]="{exact:true}">Office</a>
    </div>
    <div class="col">
        <h5 style="opacity: 0;">xxx</h5>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']"  routerLinkActive="active-link" [queryParams]="{ category:  'Retail'}" [routerLinkActiveOptions]="{exact:true}">Retail</a>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']"  routerLinkActive="active-link" [queryParams]="{ category:  'Industrial'}" [routerLinkActiveOptions]="{exact:true}">Industrial</a>
        <a (click)="onclicked()" [routerLink]="['/properties/property-portfolio']"  routerLinkActive="active-link" [queryParams]="{ category:  'CEELogistics'}" [routerLinkActiveOptions]="{exact:true}">CEE Logistics</a>
    </div>
    <div class="col padding-top">
        <app-contact-header></app-contact-header>
    </div>
</div>