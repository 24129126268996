import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-editions',
  templateUrl: './editions.component.html',
  styleUrls: ['./editions.component.scss'],
})
export class EditionsComponent implements OnInit {
  id: string = '';
  editions = [
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-1-banner.webp',
      firstBlockTitle: 'The future according to Fortress',
      firstBlockText:
        'In the first edition of the FOURsight newsletter, Steven Brown, CEO of Fortress Real Estate Investments , gives his views on how Fortress has performed in the past year, what the business has learnt from the pandemic and what the retail and logistics divisions are planning for the next 6 months to take advantage of the opportunities in these challenging times.',
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: 'the-future-according-to-fortress',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Logistics will be a part of the rebuild of the economic recovery',
      secondBlockText:
        'Jason Cooper, Head of Development for Fortress Logistics explains how logistics is the backbone of an e-commerce world and by having the right properties and infrastructure in place is crucial to ensuring a smooth and effective supply chain.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID:
        'logistics-will-be-a-part-of-the-rebuild-of-the-economic-recovery',
      secondBlockImage:
        "url('../../../../assets/images/foursight/article-2.webp')",
      thirdBlockTitle: 'How the Coronavirus Will Reshape Architecture',
      thirdBlockText:
        'In this article, the writer explores how the effects of the pandemic have made us reconsider the way we want to live and work going forward. “In recent months, we have arrived at a new juncture of disease and architecture, where fear of contamination again controls what kinds of spaces we want to be in.” The article includes insights into how the design of domestic, office and city spaces will change in near future.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: 'how-the-coronavirus-will-reshape-architecture',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/article-3.webp')",
      fourthBlockTitle: 'Consumers drive change in retail sector',
      fourthBlockText:
        'The retail sector is a crucial nexus for the consumer-driven economy and will be pivotal in SA’s post lockdown recovery. Retail Executive Director, Vuso Majija, discusses how changing consumer behaviour has a positive influence on the retail sector.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: 'consumers-drive-change-in-retail-sector',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/article-4.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-2-banner.webp',
      firstBlockTitle: "Steven's View",
      firstBlockText:
        'We recently released our interim results for the period ending December 2020. In my view, it is an exceptionally good set of results, given the economic circumstances and the Covid lockdown. For me, the most exciting highlight of these results is that since the end of June 2020, we have let and secured tenants for 340,000m² of our 1 million square metre logistics real estate pipeline.',
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: 'stevens-view',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle: 'The Retail Evolution',
      secondBlockText:
        'The rhetoric of “retail is dead” is incorrect. In fact, retail is advancing at a rapid rate to meet the consumer demands for in-store innovation and service solutions for their shopping requirements in this post-Covid era.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID: 'the-retail-evolution',
      secondBlockImage:
        "url('../../../../assets/images/foursight/retail-revolution.webp')",
      thirdBlockTitle: 'Logistics Logic',
      thirdBlockText:
        'The whole logistics market has changed and in Fortress’s case, for the better. Our long-term strategy, pivoting the business to a two-thirds logistics real estate weighting composed of state-of-the art modern logistics boxes in key urban locations – in South Africa and Europe – has helped us to buck the trend in what has been a lacklustre year in the real estate sector.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: 'logistics-logic',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/logistics-logic.webp')",
      fourthBlockTitle: 'Broad Stroke',
      fourthBlockText:
        'We recently acquired two modern high-quality logistics parks in strategic locations in Poland. Ideally located to supply high-growth western European economies, these signature acquisitions deepen and extend the investment proposition of South Africa’s leading supplier of quality, prime located logistics real estate.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: 'broad-stroke',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/broad-stroke.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-3-banner.webp',
      firstBlockTitle: "Steven's View",
      firstBlockText:
        "Steven Brown, CEO of Fortress Ltd, shares his views on the company's annual results and reflects on the progress made in the past year, despite the challenges faced.",
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: 'stevens-view-3',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Pick n Pay and Fortress co-invest in a super distribution centre development',
      secondBlockText:
        'We are proud of our partnership with Pick N Pay at Eastport Logistics Park in Gauteng which will see Fortress Logistics developing one of the largest FMCG distribution centres in Africa. This deal cements our business strategy of developing and owning two-thirds of our portfolio in logistics, making us the largest owner and ongoing developer of core, premium-grade logistics real estate in South Africa.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID:
        'pick-n-pay-and-fortress-co-invest-in-a-super-distribution-centre-development',
      secondBlockImage:
        "url('../../../../assets/images/foursight/pick-n-pay.webp')",
      thirdBlockTitle:
        'Why Central and Eastern Europe is the property frontier for SA REITs',
      thirdBlockText:
        'Central and Eastern Europe (CEE) has attracted significant interest from South African investors in real estate who have sought to diversify their portfolios and satisfy their appetite for yield-enhancing assets which is why in 2021 Fortress invested in their first two logistics parks in Poland and recently announced their first acquisition of a logistics park in Romania.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID:
        'why-central-and-eastern-europe-is-the-property-frontier-for-sa-reits',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/investing.webp')",
      fourthBlockTitle:
        'We continue to invest in solar energy at our Retail parks',
      fourthBlockText:
        'Fortress has launched a total of R900 million sustainability-linked bonds aligned to its ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID:
        'we-continue-to-invest-in-solar-energy-at-our-retail-parks',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/sustain.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-4-banner.webp',
      firstBlockTitle:
        "All-time high occupancy levels boost Fortress's Interim Results",
      firstBlockText:
        "Steven Brown, CEO of Fortress, gives an overview of the company's Interim Results and shares his views on why there is a lot to be positive about in the South African real estate industry.",
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: '20',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Pick n Pay and Fortress co-invest in a super distribution centre development',
      secondBlockText:
        'We are proud of our partnership with Pick N Pay at Eastport Logistics Park in Gauteng which will see Fortress Logistics developing one of the largest FMCG distribution centres in Africa. This deal cements our business strategy of developing and owning two-thirds of our portfolio in logistics, making us the largest owner and ongoing developer of core, premium-grade logistics real estate in South Africa.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID: '13',
      secondBlockImage:
        "url('../../../../assets/images/foursight/pick-n-pay.webp')",
      thirdBlockTitle:
        'Why Central and Eastern Europe is the property frontier for SA REITs',
      thirdBlockText:
        'Central and Eastern Europe (CEE) has attracted significant interest from South African investors in real estate who have sought to diversify their portfolios and satisfy their appetite for yield-enhancing assets which is why in 2021 Fortress invested in their first two logistics parks in Poland and recently announced their first acquisition of a logistics park in Romania.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: '8',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/investing.webp')",
      fourthBlockTitle:
        'We continue to invest in solar energy at our Retail parks',
      fourthBlockText:
        'Fortress has launched a total of R900 million sustainability-linked bonds aligned to its ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: '7',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/sustain.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-5-banner.webp',
      firstBlockTitle: 'Fortress leads on exponential growth',
      firstBlockText:
        'Steven Brown, CEO of Fortress Real Estate, discusses the highlights from the group’s Annual Results and details the positive progress being made in the retail and logistics portfolios.',
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: '20',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Pick n Pay and Fortress co-invest in a super distribution centre development',
      secondBlockText:
        'We are proud of our partnership with Pick N Pay at Eastport Logistics Park in Gauteng which will see Fortress Logistics developing one of the largest FMCG distribution centres in Africa. This deal cements our business strategy of developing and owning two-thirds of our portfolio in logistics, making us the largest owner and ongoing developer of core, premium-grade logistics real estate in South Africa.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID: '13',
      secondBlockImage:
        "url('../../../../assets/images/foursight/pick-n-pay.webp')",
      thirdBlockTitle:
        'Why Central and Eastern Europe is the property frontier for SA REITs',
      thirdBlockText:
        'Central and Eastern Europe (CEE) has attracted significant interest from South African investors in real estate who have sought to diversify their portfolios and satisfy their appetite for yield-enhancing assets which is why in 2021 Fortress invested in their first two logistics parks in Poland and recently announced their first acquisition of a logistics park in Romania.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: '8',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/investing.webp')",
      fourthBlockTitle:
        'We continue to invest in solar energy at our Retail parks',
      fourthBlockText:
        'Fortress has launched a total of R900 million sustainability-linked bonds aligned to its ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: '7',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/sustain.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-4-banner.webp',
      firstBlockTitle:
        'Fortress hits record R30 billion in direct real estate assets',
      firstBlockText:
        "Steven Brown, Fortress CEO summarises the group's Annual Results that were released at the beginning of September and shares an overview of the sustainability initiatives being implemented by the company.",
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: '20',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Pick n Pay and Fortress co-invest in a super distribution centre development',
      secondBlockText:
        'We are proud of our partnership with Pick N Pay at Eastport Logistics Park in Gauteng which will see Fortress Logistics developing one of the largest FMCG distribution centres in Africa. This deal cements our business strategy of developing and owning two-thirds of our portfolio in logistics, making us the largest owner and ongoing developer of core, premium-grade logistics real estate in South Africa.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID: '13',
      secondBlockImage:
        "url('../../../../assets/images/foursight/pick-n-pay.webp')",
      thirdBlockTitle:
        'Why Central and Eastern Europe is the property frontier for SA REITs',
      thirdBlockText:
        'Central and Eastern Europe (CEE) has attracted significant interest from South African investors in real estate who have sought to diversify their portfolios and satisfy their appetite for yield-enhancing assets which is why in 2021 Fortress invested in their first two logistics parks in Poland and recently announced their first acquisition of a logistics park in Romania.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: '8',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/investing.webp')",
      fourthBlockTitle:
        'We continue to invest in solar energy at our Retail parks',
      fourthBlockText:
        'Fortress has launched a total of R900 million sustainability-linked bonds aligned to its ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: '7',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/sustain.webp')",
    },
    {
      mainBanner:
        '../../../../assets/images/foursight/foursight-edition-4-banner.webp',
      firstBlockTitle:
        'Fortress hits record R30 billion in direct real estate assets',
      firstBlockText:
        "Steven Brown, Fortress CEO summarises the group's Annual Results that were released at the beginning of September and shares an overview of the sustainability initiatives being implemented by the company.",
      firstBlockLinkText: 'Read More',
      firstBlockLink: '/foursight/press-release/article',
      firstBlockID: '20',
      firstBlockImage:
        "url('../../../../assets/images/foursight/stevens-view.webp')",
      secondBlockTitle:
        'Pick n Pay and Fortress co-invest in a super distribution centre development',
      secondBlockText:
        'We are proud of our partnership with Pick N Pay at Eastport Logistics Park in Gauteng which will see Fortress Logistics developing one of the largest FMCG distribution centres in Africa. This deal cements our business strategy of developing and owning two-thirds of our portfolio in logistics, making us the largest owner and ongoing developer of core, premium-grade logistics real estate in South Africa.',
      secondBlockLinkText: 'Read More',
      secondBlockLink: '/foursight/press-release/article',
      secondBlockID: '13',
      secondBlockImage:
        "url('../../../../assets/images/foursight/pick-n-pay.webp')",
      thirdBlockTitle:
        'Why Central and Eastern Europe is the property frontier for SA REITs',
      thirdBlockText:
        'Central and Eastern Europe (CEE) has attracted significant interest from South African investors in real estate who have sought to diversify their portfolios and satisfy their appetite for yield-enhancing assets which is why in 2021 Fortress invested in their first two logistics parks in Poland and recently announced their first acquisition of a logistics park in Romania.',
      thirdBlockLinkText: 'Read More',
      thirdBlockLink: '/foursight/press-release/article',
      thirdBlockID: '8',
      thirdBlockImage:
        "url('../../../../assets/images/foursight/investing.webp')",
      fourthBlockTitle:
        'We continue to invest in solar energy at our Retail parks',
      fourthBlockText:
        'Fortress has launched a total of R900 million sustainability-linked bonds aligned to its ongoing strategic ESG plan to scale up its renewable energy outputs and deliver more solar energy to the national grid.',
      fourthBlockLinkText: 'Read More',
      fourthBlockLink: '/foursight/press-release/article',
      fourthBlockID: '7',
      fourthBlockImage:
        "url('../../../../assets/images/foursight/sustain.webp')",
    },
  ];

  current = {
    mainBanner: '',
    firstBlockTitle: '',
    firstBlockText: '',
    firstBlockLinkText: '',
    firstBlockLink: '',
    firstBlockID: '',
    firstBlockImage: '',
    secondBlockTitle: '',
    secondBlockText: '',
    secondBlockLinkText: '',
    secondBlockLink: '',
    secondBlockID: '',
    secondBlockImage: '',
    thirdBlockTitle: '',
    thirdBlockText: '',
    thirdBlockLinkText: '',
    thirdBlockLink: '',
    thirdBlockID: '',
    thirdBlockImage: '',
    fourthBlockTitle: '',
    fourthBlockText: '',
    fourthBlockLinkText: '',
    fourthBlockLink: '',
    fourthBlockID: '',
    fourthBlockImage: '',
  };

  showfour: boolean = false;
  showfive: boolean = false;
  showSix: boolean = false;
  showSeven: boolean = false;
  showEight: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id === '4') {
        this.showfour = true;
      } else {
        this.showfour = false;
      }

      if (this.id === '5') {
        this.showfive = true;
      } else {
        this.showfive = false;
      }

      if (this.id === '6') {
        this.showSix = true;
      } else {
        this.showSix = false;
      }
      if (this.id === '7') {
        this.showSeven = true;
      } else {
        this.showSeven = false;
      }
      if (this.id === '8') {
        this.showEight = true;
      } else {
        this.showEight = false;
      }
    });
    this.current = this.editions[parseInt(this.id) - 1];
  }
}
