import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

declare var hbspt: any;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.loadHubSpotForm();
  }
  loadHubSpotForm() {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.charset = 'utf-8';
    script.onload = () => {
      this.createHubSpotForm();
    };
    document.body.appendChild(script);
  }

  createHubSpotForm() {
    hbspt.forms.create({
      region: 'eu1',
      portalId: '145096809',
      formId: 'ae5bc88e-2262-473c-a8d2-8a4a47f96462',
      target: '#hubspotForm'
    });
  }

}
