<div class="container">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
  <div class="filter-col">
    <!-- GLA -->
    <div class="dropdown" (click)="toggleFilterDropDown('gla')">
      <p class="subtitle">GLA</p>
      <img
        [ngClass]="{ open: showGla }"
        src="../../../../../assets/icons/icon-arrow-menu-black.svg"
        alt=""
      />
    </div>
    <div class="filterBlock rangeBlock" *ngIf="showGla">
      <ngx-slider
        (userChangeEnd)="sliderEvent()"
        [(value)]="minGlaValue"
        [(highValue)]="highGlaValue"
        [options]="options"
      ></ngx-slider>
    </div>
    <!-- GLA -->
    <!-- Categories -->
    <div class="dropdown" (click)="toggleFilterDropDown('category')">
      <p class="subtitle">Categories</p>
      <img
        [ngClass]="{ open: showCategory }"
        src="../../../../../assets/icons/icon-arrow-menu-black.svg"
        alt=""
      />
    </div>
    <div class="filterBlock" *ngIf="showCategory">
      <div class="check-group">
        <input
          type="checkbox"
          id="CEELogisitics"
          (click)="categorySearch('CEELogistics', 'click')"
          [checked]="filterCategory === 'CEELogistics'"
        />
        <label for="CEELogisitics">CEE Logistics</label>
      </div>

      <div class="check-group">
        <input
          type="checkbox"
          id="logistics"
          (click)="categorySearch('Logistics', 'click')"
          [checked]="filterCategory === 'Logistics'"
        />
        <label for="logistics">Logistics</label>
      </div>

      <div class="check-group">
        <input
          type="checkbox"
          id="industrial"
          (click)="categorySearch('Industrial', 'click')"
          [checked]="filterCategory === 'Industrial'"
        />
        <label for="industrial">Industrial</label>
      </div>

      <div class="check-group">
        <input
          type="checkbox"
          id="office"
          (click)="categorySearch('Office', 'click')"
          [checked]="filterCategory === 'Office'"
        />
        <label for="office">Office</label>
      </div>

      <div class="check-group">
        <input
          type="checkbox"
          id="retail"
          (click)="categorySearch('Retail', 'click')"
          [checked]="filterCategory === 'Retail'"
        />
        <label for="retail">Retail</label>
      </div>
    </div>
    <!-- Categories -->
    <!-- Countries -->
    <div class="dropdown" (click)="toggleFilterDropDown('area')">
      <p class="subtitle">Country</p>
      <img
        [ngClass]="{ open: showArea }"
        src="../../../../../assets/icons/icon-arrow-menu-black.svg"
        alt=""
      />
    </div>
    <div class="filterBlock" *ngIf="showArea">
      <div class="check-group">
        <input
          type="checkbox"
          id="sa"
          (click)="countrySearchHandler('South Africa', 'click')"
          [checked]="filterCountry === 'South Africa'"
        />
        <label for="sa">South Africa</label>
      </div>
      <div class="check-group">
        <input
          type="checkbox"
          id="ee"
          (click)="countrySearchHandler('Poland', 'click')"
          [checked]="filterCountry === 'Poland'"
        />
        <label for="ee">Poland</label>
      </div>
      <div class="check-group">
        <input
          type="checkbox"
          id="romania"
          (click)="countrySearchHandler('Romania', 'click')"
          [checked]="filterCountry === 'Romania'"
        />
        <label for="romania">Romania</label>
      </div>
    </div>
    <!-- Countries -->
  </div>
  <div class="col">
    <div class="filter-top">
      <h3>{{ propList.length }} Properties</h3>
      <a class="primary-btn" (click)="compareToggle()"
        ><span *ngIf="!compareNow"
          >Compare<img
            src="../../../../../assets/icons/icon-compare.svg"
            alt="" /></span
        ><span *ngIf="compareNow"
          >Compare up to three properties<img
            src="../../../../../assets/icons/icon-close.svg"
            alt="" /></span
      ></a>
      <div class="search">
        <img
          class="mag"
          src="../../../../../assets/icons/icon-magnifier-black.svg"
          alt=""
        />
        <input
          class="searchbox"
          type="text"
          placeholder="Search by keyword"
          [(ngModel)]="searchText"
          (ngModelChange)="onTextSearchChange($event)"
        />
        <img
          class="close"
          (click)="clearTextSearch()"
          src="../../../../../assets/icons/icon-close-black.svg"
          alt=""
        />
      </div>
    </div>

    <!-- ALL PROPERTIES DISPLAY HERE -->
    <div class="properties">
      <div class="compare-prop">
        <div class="prop" *ngFor="let prop of compareArr; let i = index">
          <div
            class="col-img"
            [style.background-image]="
              getPropFeature(prop.imageBucketUrl, prop.frontPageImage)
            "
          ></div>
          <div class="content">
            <h4>{{ prop.buildingName }}</h4>
            <p class="info">
              GLA: <span>{{ prop.buildingGla }} </span>
            </p>
            <p class="info">
              Province: <span>{{ prop.province }} </span>
            </p>
            <p class="info">
              Address: <span>{{ prop.streetAddress }} </span>
            </p>
            <div class="row">
              <a
                *ngIf="!vacancies"
                [routerLink]="['/properties/indivual-property']"
                [queryParams]="{ property: prop.id }"
                >View
                <img
                  src="../../../../../assets/icons/icon-arrow-green.svg"
                  alt=""
              /></a>
              <a
                *ngIf="vacancies"
                [routerLink]="['/properties/indivual-property-vacancies']"
                [queryParams]="{ property: prop.id }"
                >View
                <img
                  src="../../../../../assets/icons/icon-arrow-green.svg"
                  alt=""
              /></a>
            </div>
          </div>
          <div class="stickers">
            <div class="check-group" *ngIf="compareNow">
              <div class="box" (click)="removeCheckItem(prop.id)">
                <img
                  *ngIf="isComparedHandler(prop.id)"
                  src="../../../../../assets/icons/icon-check.svg"
                  alt=""
                />
              </div>
            </div>
            <img
              *ngFor="
                let feature of getFeatures(prop.buildingFeatures);
                let i = index
              "
              [src]="getImage(feature)"
              alt=""
              [title]="feature"
            />
          </div>
        </div>
        <div class="hr"></div>
      </div>
      <div
        class="prop"
        *ngFor="
          let prop of propList
            | paginate
              : {
                  itemsPerPage: pageSize,
                  currentPage: page,
                  totalItems: count
                };
          let i = index
        "
      >
        <div
          class="col-img"
          [style.background-image]="
            getPropFeature(prop.imageBucketUrl, prop.frontPageImage)
          "
        ></div>
        <div class="content">
          <h4>{{ prop.buildingName }}</h4>
          <p class="info">
            GLA: <span>{{ prop.buildingGla }} </span>
          </p>
          <p class="info">
            Province: <span>{{ prop.province }} </span>
          </p>
          <p class="info">
            Address: <span>{{ prop.streetAddress }} </span>
          </p>
          <div class="row">
            <a
              *ngIf="!vacancies"
              [routerLink]="['/properties/indivual-property']"
              [queryParams]="{ property: prop.id }"
              >View
              <img
                src="../../../../../assets/icons/icon-arrow-green.svg"
                alt=""
            /></a>
            <a
              *ngIf="vacancies"
              [routerLink]="['/properties/indivual-property-vacancies']"
              [queryParams]="{ property: prop.id }"
              >View
              <img
                src="../../../../../assets/icons/icon-arrow-green.svg"
                alt=""
            /></a>
          </div>
        </div>
        <div class="stickers">
          <div class="check-group" *ngIf="compareNow">
            <div class="box" (click)="compareCheck(prop.id)">
              <img
                *ngIf="isComparedHandler(prop.id)"
                src="../../../../../assets/icons/icon-check.svg"
                alt=""
              />
            </div>
          </div>

          <img
            *ngFor="
              let feature of getFeatures(prop.buildingFeatures);
              let i = index
            "
            [src]="getImage(feature)"
            alt=""
            [title]="feature"
          />
        </div>
      </div>
      <pagination-controls
        previousLabel="Prev"
        nextLabel="Next"
        [responsive]="true"
        (pageChange)="handlePageChange($event, 'click')"
      ></pagination-controls>
    </div>
  </div>
</div>
