<div class="container">
  <div class="col text-container">
    <h4>Our Properties</h4>
    <p>
      Browse our portfolio of quality properties in logistics, retail and
      office. <br/> You can also find vacancies and properties for sale.
    </p>

    <a
      class="primary-btn desktop"
      [routerLink]="['/properties/property-portfolio']"
      >Explore all properties</a
    >
  </div>

  <div class="col full-width">
    <div
      class="block"
      *ngFor="let prop of properties; let i = index"
      [routerLink]="['/properties/property-portfolio']"
      [queryParams]="{ category: prop.proptypeURL }"
    >
      <img [src]="prop.icon" />
      <p>{{ prop.proptype }}</p>
    </div>
    
  </div>
  <a class="primary-btn mobile" [routerLink]="['/properties/all']"
      >Explore all properties</a
    >
</div>
