<div class="bg">
    <div class="container">
        <div class="content">
            <h1 class="main-title-colour">Fortress</h1>
            <h1 class="main-title">Broker Registration</h1>
        </div>
    </div>
    <div class="form-container">
        <div class="form-inner">
            <div class="col">
                <div id="hubspotForm"></div>

            </div>

            <!-- <div class="col">
                <img src="../../../../assets/images/whistle-callout.svg" alt=""/>
                
            </div>
            <div class="col">
                <p>Fortress broker registration</p>
                <p class="text">Please submit  your details using the form below.</p>
                <div class="hr"></div>
               
                <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
                    <p>NAME</p>
                    <input id="first-name" type="text" formControlName="firstName" placeholder="Your Firstname">
                    <div *ngIf="f.firstName.touched && f.firstName.invalid">
                        <small class="text-danger">
                            Firstname is required
                        </small>
                    </div>
                    <input id="last-name" type="text" formControlName="lastName" placeholder="Your Lastname">
                    <div *ngIf="f.lastName.touched && f.lastName.invalid">
                        <small class="text-danger">
                            Lastname is required
                        </small>
                    </div>
                    <p>COMPANY</p>
                    <input id="company" type="text" formControlName="company" placeholder="Company Name">
                    <div *ngIf="f.company.touched && f.company.invalid">
                        <small class="text-danger">
                            Company Name is required
                        </small>
                    </div>
                    <input id="position" type="text" formControlName="position" placeholder="Position At Company">
                    <p>CONTACT DETAILS</p>
                    <input id="email" type="text" formControlName="email" placeholder="Email Address">
                    <div *ngIf="f.email.touched && f.email.invalid">
                        <small class="text-danger">
                            Valid email is required
                        </small>
                    </div>
                    <input id="number" type="text" formControlName="number" placeholder="Mobile Number">
                    <div *ngIf="f.number.touched && f.number.invalid">
                        <small class="text-danger">
                            Valid number is required
                        </small>
                    </div>
                    <label>
                        <input (click)="toggle()" type="radio" value="consent" formControlName="consent">
                        <span>I give my consent to recieve direct marketing from Fortress by email in relation to its mailing list to receive news, insights, invitations and otherinformation related to its property and real estate products and services.</span>
                    </label>
                    <div *ngIf="f.consent.touched && f.consent.invalid">
                        <small class="text-danger">
                            Consent is required
                        </small>
                    </div>
                    <p>WHAT IS {{num1}}+{{num2}}?</p>
                    <input id="answer" type="text" formControlName="answer" placeholder="Answer">
                    <div *ngIf="f.answer.touched && f.answer.invalid">
                        <small class="text-danger">
                            Answer is required
                        </small>
                    </div>
                    <button class="primary-btn-arrow" type="submit" [disabled]="!profileForm.valid">Register</button>
                    <p class="error-message">{{errorMessage}}</p>
                </form> <iframe src="//js-eu1.hsforms.net/forms/embed/v2.js" scrolling="no" frameBorder="0" width="100%" height="1000px"><p>Your browser does not support iframes.</p></iframe>
            </div>-->
        </div>
    </div>
   
</div>