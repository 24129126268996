import { Component, OnInit } from '@angular/core';
import { COUNTRIES } from '../press-release-data';
import { Observable } from 'rxjs';
import { ContentfulService } from '../../../services/contentful.service';

export interface articles {
  title: string;
  excerpt: string;
  bannerImg: string;
  content: string;
  url: string;
  newsletterLink: string;
}

@Component({
  selector: 'app-press-release',
  templateUrl: './press-release.component.html',
  styleUrls: ['./press-release.component.scss'],
})
export class PressReleaseComponent implements OnInit {
  lessons$!: Observable<any>;
  allArr: articles[] = [];

  constructor(private contentful: ContentfulService) {}

  data: Array<any> = [];
  ngOnInit(): void {
    var countAll = 0;

    this.lessons$ = this.contentful.getArticles();
    this.contentful.getArticles().subscribe((result) => {
      for (let i = 0; i < result.length; i++) {
        var title = (result[i].fields as { title: string }).title;
        var excerpt = (result[i].fields as { excerpt: string }).excerpt;
        var holdtest = (result[i].fields as { bannerImg: any }).bannerImg
          .fields;
        if (holdtest) {
          var bannerImg = (result[i].fields as { bannerImg: any }).bannerImg
            .fields.file.url;
        } else {
          var bannerImg = null;
        }
        var content = (result[i].fields as { content: string }).content;
        var url = (result[i].fields as { url: string }).url;

        var newsletterLink = (result[i].fields as { newsletterLink: string })
          .newsletterLink;

        this.allArr[countAll] = {
          title: '',
          excerpt: '',
          bannerImg: '',
          content: '',
          url: '',
          newsletterLink: '',
        };
        this.allArr[countAll].title = title;
        this.allArr[countAll].excerpt = excerpt;
        this.allArr[countAll].bannerImg = bannerImg;
        this.allArr[countAll].content = content;
        this.allArr[countAll].newsletterLink = newsletterLink;
        this.allArr[countAll].url = url.toLowerCase();

        countAll++;
      }
      // _____________ this is here as the client didn't tno want news letter
      // _____________ articles to be show in the press-release page
      this.allArr = this.allArr.filter((item) => {
        return !item.newsletterLink;
      });
    });

    var getPos = localStorage.getItem('ypos');
    if (getPos) {
      window.scroll(0, +getPos);
      localStorage.removeItem('ypos');
    } else {
      window.scroll(0, 0);
    }

    this.data = COUNTRIES;
    // console.log(COUNTRIES);
  }

  getImg(img: string) {
    var hold = 'http://' + img.slice(2);
    return hold;
  }
  getLocation(event: any) {
    //var x = event.clientX;
    //var y = event.pageY;
    //console.log(event);
    //localStorage.setItem('ypos', y);
  }
}
