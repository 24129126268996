import { Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import { ModalService } from '../../_modal';

@Component({
  selector: 'app-foursight-news',
  templateUrl: './foursight-news.component.html',
  styleUrls: ['./foursight-news.component.scss']
})
export class FoursightNewsComponent implements OnInit {

  @Input() whiteNav: boolean | undefined;

  events=[
    {
      img:"../../assets/images/foursight/foursight8/RobinWilliams.jpg",
      subtitle: "Latest Event",
      category: "Event",
      date: "October 2024",
      title:"Fortress Invitational \n October 2024",
      btnText: 'Read More',
      url: "/foursight/press-release/article?id=williams-defends-fortress-invitational-title",
    },
    {
      img:"../../assets/images/latest-newsletter.png",
      subtitle: "Latest Newsletter",
      category: "FOURSIGHT / NEWSLETTER EDITION 8",
      title:"See the latest edition",
      url: "/foursight/edition?id=8",
      btnText: 'Read more'
    },
    {
      img:"../../../../assets/images/foursight/foursight8/Cornubia Ridge Logistics Park (5).jpg",
      subtitle: "Latest article",
      category: "article",
      title:"Healthy logistics demand, disposals of non-core assets and effective capital recycling",
      url: "/foursight/press-release/article?id=strong-drivers-supporting-robust-logistics-real-estate-sectors",
      btnText: 'Read more'
    },
    {
      img:"https://images.ctfassets.net/7unabnt9bnbr/3qD7CrnEz8ukCgNZ1sH2an/06191164550156e799bac15bcb02b3be/Eastport_Logistics_Park_New_proposed_warehouse__3_.jpg",
      subtitle: "Latest Development",
      category: "Development",
      title:"Powering growth at Eastport Logistics Park",
      // modalID: 'custom-modal-3',
      btnText: 'Read more',
      url: "/foursight/press-release/article?id=powering-growth-at-eastport-logistics-park"
    },
    {
      img:"../../assets/images/latest-webinar.png",
      subtitle: "Latest webinar",
      category: "Webinar",
      date: "June 2024",
      title:"Benefits of collaboration between retail property sector and local government",
      modalID: 'custom-modal-2',
      btnText: 'Watch now'
    }
  ]

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        // tablet
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '20px',
        }
      },
      {
        // mobile portrait
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      }
    ],
    prevArrow: `<svg class="slick-arrow previous" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.67285 11.9876H16.6662" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.6753 7.99219L6.66797 12.0002L10.6753 16.0082" stroke="#282A4C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `,
    nextArrow:`<svg class="slick-arrow next" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11.25" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.98633 11.9876H16.9797" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.9883 7.99219L17.0129 12.0002L12.9883 16.0082" stroke="#0A2431" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`
  };


  afterChange(e: any) {
   
    var iframes = document.querySelectorAll('iframe');
    
    Array.prototype.forEach.call(iframes, iframe => {
      iframe.contentWindow.postMessage(JSON.stringify({ event: 'command',
      func: 'pauseVideo' }), '*');
      });
  }

  getRandomInt(max:number) {
    return Math.floor(Math.random() * max);
  }

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openModal(id: string) {
    this.modalService.open(id);
    const iframe1 = this.youtubeVideo1.nativeElement;
    iframe1.src = 'https://www.youtube.com/embed/GbzKNSW0TKY';

    const iframe2 = this.youtubeVideo2.nativeElement;
    iframe2.src = 'https://www.youtube.com/embed/5Ppnd2fpMw8';

    const iframe3 = this.youtubeVideo3.nativeElement;
    iframe3.src = 'https://www.youtube.com/embed/dHoas7VBNBg';
  }
  
  @ViewChild('youtubeVideo1')
  youtubeVideo1!: ElementRef;

  @ViewChild('youtubeVideo2')
  youtubeVideo2!: ElementRef;

  @ViewChild('youtubeVideo3')
  youtubeVideo3!: ElementRef;

  closeModal1(id: string) {
      this.modalService.close(id);
      const iframe1 = this.youtubeVideo1.nativeElement;
      iframe1.src = '';
  }

  closeModal2(id: string) {
      this.modalService.close(id);
      const iframe2 = this.youtubeVideo2.nativeElement;
      iframe2.src = '';
  }

  closeModal3(id: string) {
      this.modalService.close(id);
      const iframe3 = this.youtubeVideo3.nativeElement;
      iframe3.src = '';
  }

}
