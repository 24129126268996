<div class="footer-container">
  <div class="row">
    <div class="nav-elements">
      <div class="logo">
        <img
          class="footer-logo"
          src="../../../assets/logo/logo-green.svg"
          alt=""
        />
        <div class="social-container">
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCCkLnosxubxYV-rgV8Fge4A"
            ><img src="../../../assets/icons/icon-twitter.svg" alt=""
          /></a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/fortressreitlimited/"
            ><img src="../../../assets/icons/icon-linkedin-green.svg" alt=""
          /></a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCCkLnosxubxYV-rgV8Fge4A"
            ><img src="../../../assets/icons/icon-youtube.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="col">
        <a [routerLink]="['/about/about-us']"
          >About Fortress<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['sustainability/our-impact']"
          >Sustainability<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/properties/property-portfolio']"
          >Our Properties<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/investor-relations/overview']"
          >Investor Relations<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
        <a [routerLink]="['/foursight']"
          >Foursight News<img
            src="../../../assets/icons/icon-arrow-green.svg"
            alt=""
        /></a>
      </div>
      <div class="col">
        <p>
          Block C, Cullinan Place, <br />Cullinan Close, <br />Morningside
          <br />2196 <br />South Africa
        </p>
      </div>
      <div class="col">
        <p class="address">
          Fortress Real Estate Investments Limited <br />Registration Number:
          2009/016487/06 <br />Share codes: FFA and FFB <br />ISIN: ZAE000248498
          and ZAE000248506
        </p>
      </div>
    </div>
  </div>
  <div class="copy-container">
    <p class="links-container">
      Copyright Fortress REIT 2024 |
      <a [routerLink]="['/about/paia']">Paia</a> |
      <a [routerLink]="['/privacy-notice']">Privacy Policy & Data Code</a> |
      <a href="https://popia.co.za/">popia</a> | 
      <a href="https://property.fortressfund.co.za/access-request">Data Access Request</a>
    </p>
    <p>
      The information contained in this website is for general information
      purposes only. The information is provided by Fortress and while we
      endeavour to keep the information up to date and correct, we make no
      representations or warranties of any kind, express or implied, about the
      completeness, accuracy, reliability, suitability or availability with
      respect to the website or the information, products, services, or related
      graphics contained on the website for any purpose. Any reliance you place
      on such information is therefore strictly at your own risk. In no event
      will we be liable for any loss or damage including without limitation,
      indirect or consequential loss or damage, or any loss or damage whatsoever
      arising from loss of data or profits arising out of, or in connection
      with, the use of this website. Through this website you are able to link
      to other websites which are not under the control of Fortress. We have no
      control over the nature, content and availability of those sites. The
      inclusion of any links does not necessarily imply a recommendation or
      endorse the views expressed within them. Every effort is made to keep the
      website up and running smoothly. However, Fortress takes no responsibility
      for, and will not be liable for, the website being temporarily unavailable
      due to technical issues beyond our control.
    </p>
  </div>
</div>
