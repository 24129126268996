import { Component, OnInit, Input } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { FortressPropertyService } from '../../../../services/fortress-property.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
export interface props {
  askingPrice: any;
  buildingCode: string;
  city: string;
  country: string;
  description: any;
  erfNo: any;
  latitude: number;
  latitudeString: string;
  legalEntity: any;
  lessorRegNo: any;
  location: any;
  longitude: number;
  longitudeString: string;
  marketProperty: boolean;
  modified: boolean;
  postalCode: any;
  priceOnRequest: boolean;
  propertyCode: string;
  id: number;
  buildingName: string;
  province: string;
  saleStatus: any;
  saleStatusInt: any;
  saleStatusString: any;
  salesMarketingDescription: any;
  sector: string;
  sectorInt: number;
  status: string;
  statusInt: number;
  streetAddress: string;
  streetNumber: any;
  subCategory: number;
  subCategoryInt: number;
  suburb: string;
  buildingGla: number;
  updatePropertyName: boolean;
  vatNo: any;
  videoUrl: string;
  virtualTourUrl: string;
  webUrl: string;
  youTubeUrl: any;
  _originalPropertyName: string;
  frontPageImage: string;
  imageBucketUrl: string;
  compare: boolean;
  buildingFeatures: any;
  buildingContactableList: any;
  units: any;
  buildingId: any;
  unitCount: any;
}

@Component({
  selector: 'app-search-filter-all-properties',
  templateUrl: './search-filter-all-properties.component.html',
  styleUrls: ['./search-filter-all-properties.component.scss'],
})
export class SearchFilterAllPropertiesComponent implements OnInit {
  vacancies: boolean = false;
  propList: props[] = [];
  originalPropList: props[] = [];
  filteredPropList: props[] = [];
  showCategory: boolean = false;
  showArea: boolean = false;
  showGla: boolean = true;
  searchText: string = '';

  minGlaValue: number = 0;
  highGlaValue: number = 500000;
  options: Options = {
    floor: 0,
    ceil: 500000,
  };
  page = 1;
  count = 0;
  pageSize = 10;

  // category options
  filterCategory: string = '';
  filterCountry: string = '';

  compareNow: boolean = false;
  compareArr: props[] = [];

  constructor(
    private propertyService: FortressPropertyService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.propertyService.getProperties().subscribe(
      (result) => {
        this.propList = result.filter(prop => {
          return prop.status !== "Development"
        }).sort((a, b) => b.buildingGla - a.buildingGla);
        if (this.router.url.includes('vacancies')) {
          this.vacancies = true
          this.propList = result.filter((prop) => {
            return prop.unitCount > 0;
          });
        }
        this.originalPropList = [...this.propList];
        this.filteredPropList = [...this.propList];
        
        this.queryParamsHandler();
        this.spinner.hide();
      },
      (error) => {
        console.error('Error loading properties:', error);
        this.spinner.hide();
      }
    );
  }

  onTextSearchChange(event: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: event },
      queryParamsHandling: 'merge',
    });
    this.onTextSearchHandler(event);
  }

  onTextSearchHandler(searchString: string): void {
    this.propList = this.filteredPropList.filter((prop: props) => {
      return prop.buildingName
        .toLowerCase()
        .includes(searchString.toLowerCase());
    });
  }

  handlePageChange(event: number, action: string): void {
    this.page = event;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: event },
      queryParamsHandling: 'merge',
    });
    if (action === 'click') {
      const elementList = document.querySelectorAll('.filter-top');
      const element = elementList[0] as HTMLElement;
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getPropFeature(bucket: string, img: string) {
    if (img === null || bucket === null) {
      return 'url(../../../../../assets/images/no-prop-image.webp)';
    } else {
      img = img.replace(/\s/g, '%20');
      return 'url(' + bucket + '/' + img + ')';
    }
  }

  getFeatures(features: any) {
    if (features === null) {
      return null;
    } else {
      var hold: Array<string> = [];
      if (features.internet === true) {
        hold.push('internet');
      }
      if (features.generators === true) {
        hold.push('generator');
      }
      if (features.greenBuilding === true) {
        hold.push('green');
      }
      return hold;
    }
  }
  toggleFilterDropDown(type: string) {
    switch (type) {
      case 'category':
        this.showCategory = !this.showCategory;
        break;
      case 'area':
        this.showArea = !this.showArea;
        break;
      case 'province':
        // this.showProvince = !this.showProvince;
        break;
      case 'gla':
        this.showGla = !this.showGla;
        break;
      case 'feature':
        // this.showFeature = !this.showFeature;
        break;
    }
  }
  clearTextSearch() {
    this.searchText = '';
    this.page = 1;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null, page: null },
      queryParamsHandling: 'merge',
    });
  }

  categorySearch(word: string, event: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { category: word },
      queryParamsHandling: 'merge',
    });

    if (event === 'click') {
      // if the same category is clicked remove it
      if (this.filterCategory === word) {
        this.filterCategory = '';
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { category: null },
          queryParamsHandling: 'merge',
        });
        this.filterVacancy();
        return;
      }
      this.filterCategory = word;
    }
    this.filterVacancy();
  }

  filterVacancy() {
    this.filteredPropList = this.originalPropList
      .filter((o) => {
        return (
          o.buildingGla <= this.highGlaValue &&
          o.buildingGla >= this.minGlaValue
        );
      })
      .filter((property) => {
        if (this.filterCategory === '') {
          return true;
        }
        return property.sector === this.filterCategory;
      })
      .filter((property) => {
        if (this.filterCountry === '') {
          return true;
        }
        return property.country === this.filterCountry;
      });
    this.onTextSearchHandler(this.searchText);
  }

  compareToggle() {
    this.compareNow = !this.compareNow;
    this.compareArr = [];
  }

  getImage(type: String) {
    switch (type) {
      case 'green':
        return '../../../../../assets/icons/property-features/small-green.svg';
      case 'internet':
        return '../../../../../assets/icons/property-features/small-internet.svg';
      case 'generator':
        return '../../../../../assets/icons/property-features/small-generator.svg';
      default:
        return '';
    }
  }

  compareCheck(propId: number) {
    if (this.compareArr.length >= 3) {
      return;
    }
    const filterProp = this.propList.find((property) => property.id === propId);
    if (!filterProp) {
      return;
    }
    const filterPropInCompareArr = this.compareArr.find(
      (property) => property.id === propId
    );
    if (filterPropInCompareArr) {
      return;
    }
    this.compareArr.push(filterProp);
  }
  removeCheckItem(propId: number) {
    const indexToRemove = this.compareArr.findIndex(
      (property) => property.id === propId
    );
    if (indexToRemove !== -1) {
      this.compareArr.splice(indexToRemove, 1);
    }
  }

  sliderEvent() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { glaMin: this.minGlaValue, glaMax: this.highGlaValue },
      queryParamsHandling: 'merge',
    });
    this.filterVacancy();
  }

  countrySearchHandler = (country: string, event: string): void => {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { country: country },
      queryParamsHandling: 'merge',
    });

    if (event === 'click') {
      if (this.filterCountry === country) {
        this.filterCountry = '';
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { country: null },
          queryParamsHandling: 'merge',
        });
        this.filterVacancy();
        return;
      }
      this.filterCountry = country;
    }
    this.filterVacancy();
  };

  queryParamsHandler() {
    this.route.queryParams.subscribe((params) => {
      // Only re-run filter if glaMin or glaMax have actually changed
      if (params.glaMin || params.glaMax) {
        this.minGlaValue = params.glaMin;
        this.highGlaValue = params.glaMax;
        this.filterVacancy();
      } else {
        this.minGlaValue = 0;
        this.highGlaValue = 500000;
      }

      // Only re-run category filter if the category has changed
      if (params.category) {
        this.filterCategory = params.category;
        this.categorySearch(params.category, '');
      } else {
        this.filterCategory = '';
      }

      if (params.country) {
        this.filterCountry = params.country;
        this.countrySearchHandler(params.country, '');
      } else {
        this.filterCountry = '';
      }
      // Only run text search if the search term has changed
      if (params.search) {
        this.searchText = params.search;
        this.onTextSearchHandler(params.search);
      }

      // Only handle page change if the page has changed
      if (params.page) {
        this.handlePageChange(params.page, '');
      }
      this.filterVacancy();
    });
  }

  isComparedHandler(comparePropId: number) {
    if (
      this.compareArr.filter((prop) => {
        return prop.id === comparePropId;
      }).length === 1
    ) {
      return true;
    }
    return false;
  }
}
