import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentfulService } from '../../../../services/contentful.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
export interface docs {
  category: string;
  date: string;
  published: string;
  title: string;
  media: string;
}

@Component({
  selector: 'app-documents-search-filter',
  templateUrl: './documents-search-filter.component.html',
  styleUrls: ['./documents-search-filter.component.scss'],
})
export class DocumentsSearchFilterComponent implements OnInit {
  sensArr: docs[] = [];
  annualArr: docs[] = [];
  intermArr: docs[] = [];
  presentationArr: docs[] = [];
  dmtnArr: docs[] = [];
  beeArr: docs[] = [];
  circArr: docs[] = [];
  roArr: docs[] = [];
  moiArr: docs[] = [];
  prospArr: docs[] = [];
  allArr: docs[] = [];

  showDataArr: docs[] = [];
  showDataArrIntegrated: docs[] = [];
  searchText: string = '';
  dateSearch: string = '';
  document: string = '';
  dateToSort: string = 'All';

  date2025: boolean = false;
  date2024: boolean = true;
  date2023: boolean = false;
  date2022: boolean = false;
  date2021: boolean = false;
  date2020: boolean = false;
  date2019: boolean = false;
  date2018: boolean = false;
  date2017: boolean = false;
  date2016: boolean = false;
  date2015: boolean = false;
  date2014: boolean = false;
  date2013: boolean = false;
  date2012: boolean = false;
  date2011: boolean = false;
  date2010: boolean = false;

  showFilterDate: boolean = true;
  showCombinedIntegratedDocs: boolean = false;

  @ViewChild('scrollTargetElement') scrollTargetElement: ElementRef | undefined;

  categories = [
    {
      name: 'All',
    },
    {
      name: 'SENS',
    },
    {
      name: 'Integrated Financial Reports',
    },
    {
      name: 'Presentations',
    },
    {
      name: 'Webcast',
    },
    {
      name: 'DMTN Programme',
    },
    {
      name: 'BBBEE Certification',
    },
    {
      name: 'Circulars',
    },
    {
      name: 'Rights Offers',
    },
    {
      name: 'MOI & Capital Conversion',
    },
    {
      name: 'Prospectus & Listing Particulars',
    },
  ];
  dateRange = [
    { name: 'All' },
    { name: '2024' },
    { name: '2023' },
    { name: '2022' },
    { name: '2021' },
    { name: '2020' },
    { name: '2019' },
    { name: '2018' },
    { name: '2017' },
    { name: '2016' },
    { name: '2015' },
    { name: '2014' },
    { name: '2013' },
    { name: '2012' },
    { name: '2011' },
    { name: '2010' },
  ];

  showCategories: boolean = true;
  showDates: boolean = false;
  selectedDate: string = this.dateRange[0].name;
  activeCat: number = 0;
  lessons$!: Observable<any>;
  allContain: boolean = true;

  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 20;
  pageSizes = [20, 40, 60];

  selectedDateString = '2024';

  constructor(
    private scroller: ViewportScroller,
    private contentful: ContentfulService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.route.queryParams.subscribe((params) => {
      this.document = params.document;
    });
  }

  ngOnInit(): void {
    var countArrSens = 0;
    var countannualArr = 0;
    var countintermArr = 0;
    var countpresentationArr = 0;
    var countdmtnArr = 0;
    var countbeeArr = 0;
    var countcircArr = 0;
    var countroArr = 0;
    var countmoiArr = 0;
    var countprospArr = 0;
    var countAll = 0;

    this.lessons$ = this.contentful.getContents();
    this.contentful.getContents().subscribe((result) => {
      //console.log(result.length);
      for (let i = 0; i < result.length; i++) {
        var holdCat = (result[i].fields as { category: string }).category;
        var holdTitle = (result[i].fields as { title: string }).title;
        var holdDate = (result[i].fields as { date: string }).date;
        var holdPublished = (result[i].fields as { published: string })
          .published;
        var holdtest = (result[i].fields as { media: any }).media.fields;
        if (holdtest) {
          var holdMedia = (result[i].fields as { media: any }).media.fields.file
            .url;
        } else {
          var holdMedia = null;
        }
        if (holdPublished) {
          if (
            holdCat !== 'Presentations' &&
            holdCat !== 'DMTN Programme' &&
            holdCat !== 'BBBEE Certification' &&
            holdCat !== 'Circulars' &&
            holdCat !== 'Right Offers' &&
            holdCat !== 'MOI & Capital Conversion' &&
            holdCat !== 'Prospectus & Listing Particulars'
          ) {
            this.allArr[countAll] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.allArr[countAll].category = holdCat;
            this.allArr[countAll].title = holdTitle;
            this.allArr[countAll].date = holdDate;
            this.allArr[countAll].published = holdPublished;
            this.allArr[countAll].media = holdMedia;
            countAll++;
          }

          if (holdCat === 'SENS') {
            this.sensArr[countArrSens] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.sensArr[countArrSens].category = holdCat;
            this.sensArr[countArrSens].title = holdTitle;
            this.sensArr[countArrSens].date = holdDate;
            this.sensArr[countArrSens].published = holdPublished;
            this.sensArr[countArrSens].media = holdMedia;
            countArrSens++;
          }

          if (holdCat === 'Annual Reports') {
            this.annualArr[countannualArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.annualArr[countannualArr].category = holdCat;
            this.annualArr[countannualArr].title = holdTitle;
            this.annualArr[countannualArr].date = holdDate;
            this.annualArr[countannualArr].published = holdPublished;
            this.annualArr[countannualArr].media = holdMedia;
            countannualArr++;
          }

          if (holdCat === 'Interim Reports') {
            this.intermArr[countintermArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.intermArr[countintermArr].category = holdCat;
            this.intermArr[countintermArr].title = holdTitle;
            this.intermArr[countintermArr].date = holdDate;
            this.intermArr[countintermArr].published = holdPublished;
            this.intermArr[countintermArr].media = holdMedia;
            countintermArr++;
          }

          if (holdCat === 'Presentations') {
            this.presentationArr[countpresentationArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.presentationArr[countpresentationArr].category = holdCat;
            this.presentationArr[countpresentationArr].title = holdTitle;
            this.presentationArr[countpresentationArr].date = holdDate;
            this.presentationArr[countpresentationArr].published =
              holdPublished;
            this.presentationArr[countpresentationArr].media = holdMedia;
            countpresentationArr++;
          }

          if (holdCat === 'DMTN Programme') {
            this.dmtnArr[countdmtnArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.dmtnArr[countdmtnArr].category = holdCat;
            this.dmtnArr[countdmtnArr].title = holdTitle;
            this.dmtnArr[countdmtnArr].date = holdDate;
            this.dmtnArr[countdmtnArr].published = holdPublished;
            this.dmtnArr[countdmtnArr].media = holdMedia;
            countdmtnArr++;
          }

          if (holdCat === 'BBBEE Certification') {
            this.beeArr[countbeeArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.beeArr[countbeeArr].category = holdCat;
            this.beeArr[countbeeArr].title = holdTitle;
            this.beeArr[countbeeArr].date = holdDate;
            this.beeArr[countbeeArr].published = holdPublished;
            this.beeArr[countbeeArr].media = holdMedia;
            countbeeArr++;
          }

          if (holdCat === 'Circulars') {
            this.circArr[countcircArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.circArr[countcircArr].category = holdCat;
            this.circArr[countcircArr].title = holdTitle;
            this.circArr[countcircArr].date = holdDate;
            this.circArr[countcircArr].published = holdPublished;
            this.circArr[countcircArr].media = holdMedia;
            countcircArr++;
          }

          if (holdCat === 'Right Offers') {
            this.roArr[countroArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.roArr[countroArr].category = holdCat;
            this.roArr[countroArr].title = holdTitle;
            this.roArr[countroArr].date = holdDate;
            this.roArr[countroArr].published = holdPublished;
            this.roArr[countroArr].media = holdMedia;
            countroArr++;
          }

          if (holdCat === 'MOI & Capital Conversion') {
            this.moiArr[countmoiArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.moiArr[countmoiArr].category = holdCat;
            this.moiArr[countmoiArr].title = holdTitle;
            this.moiArr[countmoiArr].date = holdDate;
            this.moiArr[countmoiArr].published = holdPublished;
            this.moiArr[countmoiArr].media = holdMedia;
            countmoiArr++;
          }

          if (holdCat === 'Prospectus & Listing Particulars') {
            this.prospArr[countprospArr] = {
              category: '',
              date: '',
              published: '',
              title: '',
              media: '',
            };
            this.prospArr[countprospArr].category = holdCat;
            this.prospArr[countprospArr].title = holdTitle;
            this.prospArr[countprospArr].date = holdDate;
            this.prospArr[countprospArr].published = holdPublished;
            this.prospArr[countprospArr].media = holdMedia;
            countprospArr++;
          }
        }
      }
      this.showDataArr = this.allArr;

      this.selectCategory(0);
      this.filterDate('2024');
      if (this.document !== undefined) {
        switch (this.document) {
          case 'sens':
            this.selectCategory(1);
            break;
          case 'annual-reports':
            this.showCombinedIntegratedDocs = true;
            this.selectCategory(2);
            break;
          case 'presentations':
            this.selectCategory(3);
            break;
          case 'webcast':
            this.selectCategory(4);
            break;
          case 'dmtn-programme':
            this.selectCategory(5);
            break;
          case 'bbbee-certification':
            this.selectCategory(6);
            break;
          case 'circulars':
            this.selectCategory(7);
            break;
          case 'right-offers':
            this.selectCategory(8);
            break;
          case 'moi-capital-conversion':
            this.selectCategory(9);
            break;
          case 'prospectus-listing-particulars':
            this.selectCategory(10);
            break;
        }
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    const elementList = document.querySelectorAll('.target');
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  toggleCategory() {
    this.showCategories = !this.showCategories;
  }
  toggleDate() {
    this.showDates = !this.showDates;
  }

  selectDate(date: string) {
    if (date === 'All') {
      this.selectedDate = date;
      this.toggleDate();
    } else {
      this.selectedDate = date;
      this.dateSearch = date;
      this.toggleDate();
    }
  }

  selectDate1(date: string) {
    if (date === 'All') {
      this.selectedDate = date;
      this.toggleDate();
      this.dateToSort = 'All';
    } else {
      this.selectedDate = date;
      this.dateSearch = date;
      this.dateToSort = date;
      this.toggleDate();
    }
  }

  filterDate(date: string) {
    this.page = 1;
    //console.log(this.showDataArr);
    this.dateSearch = date;
    if (date === '') {
    } else {
      this.selectedDateString = date;
    }

    console.log(date);
    console.log(this.date2024);
    console.log(this.date2025);
    

    switch (date) {
      case '2025':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = true;
        break;
      case '2024':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = true;
        this.date2025 = false;
        break;
      case '2023':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = true;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2022':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = true;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2021':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = true;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2020':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = true;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2019':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = true;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2018':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = true;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2017':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = true;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2016':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = true;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2015':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = true;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2014':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = true;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2013':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = true;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2012':
        this.date2010 = false;
        this.date2011 = false;
        this.date2012 = true;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2011':
        this.date2010 = false;
        this.date2011 = true;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
      case '2010':
        this.date2010 = true;
        this.date2011 = false;
        this.date2012 = false;
        this.date2013 = false;
        this.date2014 = false;
        this.date2015 = false;
        this.date2016 = false;
        this.date2017 = false;
        this.date2018 = false;
        this.date2019 = false;
        this.date2020 = false;
        this.date2021 = false;
        this.date2022 = false;
        this.date2023 = false;
        this.date2024 = false;
        this.date2025 = false;
        break;
    }
  }

  selectCategory(index: number) {
    this.scroller.scrollToAnchor('target');
    this.activeCat = index;
    switch (index) {
      case 0:
        this.showCombinedIntegratedDocs = false;
        this.searchText = '';
        this.showDataArr = this.allArr;
        this.filterDate(this.selectedDateString);
        this.allContain = true;
        this.showFilterDate = true;
        break;
      case 1:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="SENS";
        this.showDataArr = this.sensArr;
        console.log(this.selectedDateString);
        this.filterDate(this.selectedDateString);
        this.allContain = true;
        this.searchText = '';
        this.showFilterDate = true;
        break;
      case 2:
        this.showCombinedIntegratedDocs = true;
        //this.searchText="Annual Reports";
        this.showDataArr = this.annualArr;
        this.showDataArrIntegrated = this.intermArr;
        this.filterDate(this.selectedDateString);
        this.allContain = true;
        this.searchText = '';
        this.showFilterDate = true;
        break;
      case 3:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="Presentations";
        this.showDataArr = this.presentationArr;
        this.filterDate(this.selectedDateString);
        this.allContain = true;
        this.searchText = '';
        this.showFilterDate = true;
        break;
      case 4:
        this.showCombinedIntegratedDocs = false;
        this.searchText = 'Webcast';
        this.allContain = false;
        break;
      case 5:
        this.showCombinedIntegratedDocs = false;
        //              this.searchText="DMTN Programme";
        this.showDataArr = this.dmtnArr;
        this.searchText = '';
        this.filterDate('');
        this.allContain = true;
        this.showFilterDate = false;
        break;
      case 6:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="BBBEE Certification";
        this.showDataArr = this.beeArr;
        this.searchText = '';
        this.filterDate('');
        this.allContain = true;
        this.showFilterDate = false;
        break;
      case 7:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="Circulars";
        this.showDataArr = this.circArr;
        this.searchText = '';
        this.filterDate('');
        this.showFilterDate = false;
        this.allContain = true;
        break;
      case 8:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="Right Offers";
        this.showDataArr = this.roArr;
        this.filterDate('');
        this.searchText = '';
        this.allContain = true;
        this.showFilterDate = false;
        break;
      case 9:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="MOI & Capital Conversion";
        this.showDataArr = this.moiArr;
        this.filterDate('');
        this.searchText = '';
        this.allContain = true;
        this.showFilterDate = false;
        break;
      case 10:
        this.showCombinedIntegratedDocs = false;
        //this.searchText="Prospectus & Listing Particulars";
        this.showDataArr = this.prospArr;
        this.filterDate('');
        this.searchText = '';
        this.allContain = true;
        this.showFilterDate = false;
        break;
    }
    //console.log(this.showDataArr);

    if (this.scrollTargetElement) {
      this.scrollTargetElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  getName(name: string) {
    return 'View';
  }

  getURL(url: string) {
    let newURL = 'https:' + url;
    return newURL;
  }

  getImage(cat: string) {
    let img = '';
    switch (cat) {
      case 'SENS':
        img = '../../../../../assets/icons/document/sens.svg';
        break;
      case 'Annual Reports':
        img = ' ../../../../../assets/icons/document/annual.svg';
        break;
      case 'Interim Reports':
        img = ' ../../../../../assets/icons/document/interim.svg';
        break;
      case 'Presentations':
        img = ' ../../../../../assets/icons/document/presentation.svg';
        break;
      case 'Webcast':
        img = ' ../../../../../assets/icons/document/webcast.svg';
        break;
      case 'DMTN Programme':
        img = ' ../../../../../assets/icons/document/dmtn.svg';
        break;
      case 'BBBEE Certification':
        img = ' ../../../../../assets/icons/document/bbbee.svg';
        break;
      case 'Circulars':
        img = ' ../../../../../assets/icons/document/circulars.svg';
        break;
      case 'Right Offers':
        img = ' ../../../../../assets/icons/document/offers.svg';
        break;
      case 'MOI & Capital Conversion':
        img = ' ../../../../../assets/icons/document/moi.svg';
        break;
      case 'Prospectus & Listing Particulars':
        img = ' ../../../../../assets/icons/document/prospectus.svg';
        break;
    }
    return img;
  }

  clearFilter() {
    this.searchText = '';
  }
}
