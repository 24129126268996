<div class="outerAll">
  <div
    class="container"
    *ngIf="!showfour && !showfive && !showSix && !showSeven && !showEight"
  >
    <img class="bannerImg" [src]="current.mainBanner" alt="" />
    <div class="row">
      <div class="white block">
        <div class="col">
          <h2>{{ current.firstBlockTitle }}</h2>
          <p>{{ current.firstBlockText }}</p>
          <a
            *ngIf="current.firstBlockID"
            class="clearLink"
            [routerLink]="current.firstBlockLink"
            [queryParams]="{ id: current.firstBlockID }"
            target="_blank"
            >{{ current.firstBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
          <a
            *ngIf="!current.firstBlockID"
            class="clearLink"
            [routerLink]="current.firstBlockLink"
            target="_blank"
            >{{ current.firstBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
        </div>
        <div
          class="col bgCol"
          [style.background-image]="current.firstBlockImage"
        ></div>
      </div>
      <div class="blue block">
        <div
          class="col bgCol"
          [style.background-image]="current.secondBlockImage"
        ></div>
        <div class="col">
          <h2>{{ current.secondBlockTitle }}</h2>
          <p>{{ current.secondBlockText }}</p>
          <a
            *ngIf="current.secondBlockID"
            class="clearLink"
            [routerLink]="current.secondBlockLink"
            [queryParams]="{ id: current.secondBlockID }"
            target="_blank"
            >{{ current.secondBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
          <a
            *ngIf="!current.secondBlockID"
            class="clearLink"
            [routerLink]="current.secondBlockLink"
            target="_blank"
            >{{ current.secondBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="white block">
        <div class="col">
          <h2>{{ current.thirdBlockTitle }}</h2>
          <p>{{ current.thirdBlockText }}</p>
          <a
            *ngIf="current.thirdBlockID"
            class="clearLink"
            [routerLink]="current.thirdBlockLink"
            [queryParams]="{ id: current.thirdBlockID }"
            target="_blank"
            >{{ current.thirdBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
          <a
            *ngIf="!current.thirdBlockID"
            class="clearLink"
            [routerLink]="current.thirdBlockLink"
            target="_blank"
            >{{ current.thirdBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
        </div>
        <div
          class="col bgCol"
          [style.background-image]="current.thirdBlockImage"
        ></div>
      </div>
      <div class="blue block">
        <div
          class="col bgCol"
          [style.background-image]="current.fourthBlockImage"
        ></div>
        <div class="col">
          <h2>{{ current.fourthBlockTitle }}</h2>
          <p>{{ current.fourthBlockText }}</p>
          <a
            *ngIf="current.fourthBlockID"
            class="clearLink"
            [routerLink]="current.fourthBlockLink"
            [queryParams]="{ id: current.fourthBlockID }"
            target="_blank"
            >{{ current.fourthBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
          <a
            *ngIf="!current.fourthBlockID"
            class="clearLink"
            [routerLink]="current.fourthBlockLink"
            target="_blank"
            >{{ current.fourthBlockLinkText
            }}<img src="../../../assets/icons/icon-arrow-green.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div class="linkdinBlock">
      <a
        href="https://www.linkedin.com/company/fortressreitlimited/"
        target="_blank"
        ><h2>Follow us on <b>LinkedIn</b></h2></a
      >
    </div>
  </div>

  <div class="container showfour" *ngIf="showfour && !showfive">
    <img
      class="bannerImg"
      src="../../../../assets/images/foursight/foursight-edition-4-banner.webp"
      alt=""
    />
    <div class="row">
      <div class="white block">
        <div class="col">
          <h2>
            All-time high occupancy levels boost Fortress's Interim Results
          </h2>
          <p>
            Steven Brown, CEO of Fortress, gives an overview of the company's
            Interim Results and shares his views on why there is a lot to be
            positive about in the South African real estate industry.
          </p>
          <div class="rowLink">
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'all-time-high-occupancy-levels-boost-fortress-reits-interim-results-1'
              }"
              target="_blank"
              >Read more</a
            >
            <p>or</p>
            <a
              class="clearLink"
              href="https://www.youtube.com/watch?v=VRu4pH_b14A"
              target="_blank"
              >Watch more</a
            >
          </div>
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=VRu4pH_b14A"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/img2-new.webp);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=ZhYOIf7-RuM"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/img4.webp);
            "
          ></div
        ></a>
        <div class="col">
          <h2>
            The power of retail and its potential to uplift and empower
            communities
          </h2>
          <p>
            There are many innovative ways to take advantage of the
            opportunities that exist in the retail industry. The
            #RetailReinvented webinar held in April this year included a
            thought-provoking panel discussion between industry thought leaders
            on how the power of retail can be used to uplift and empower
            communities in rural and township areas.
          </p>
          <div class="rowLink">
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'the-power-of-retail-and-its-potential-to-uplift-and-empower-communities'
              }"
              >Read more</a
            >
            <p>or</p>
            <a
              class="clearLink"
              href="https://www.youtube.com/watch?v=ZhYOIf7-RuM"
              target="_blank"
              >Watch more</a
            >
          </div>
        </div>
      </div>
      <div class="white block">
        <div class="col">
          <h2>Fortress Logistics continues its growth trajectory</h2>
          <p>
            Fortress has continued to successfully pivot the business to a
            one-third convenience retail and two-thirds logistics real estate
            portfolio. This strategy has proven its resilience as the demand for
            logistics, both locally and offshore, remains buoyant. Occupancy
            levels in the logistics portfolio are at an all-time high and the
            quality of this portfolio continues to be enhanced with our new
            developments. Two examples of the exponential growth experienced in
            this portfolio over the past 6 months have been the acquisition of
            additional logistics development land in Poland and the joint
            venture with Inospace.
          </p>
          <a
            class="clearLink"
            [routerLink]="['/foursight/press-release/article']"
            [queryParams]="{
              id: 'fortress-logistics-continues-its-growth-trajectory'
            }"
            >Read more</a
          >
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=JJDf738_V3U"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/img7.webp);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=gXzSdgfPZ44"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/img8.webp);
            "
          ></div
        ></a>
        <div class="col">
          <h2>
            Fortress issues a further R1.3 billion in sustainability-linked
            bonds
          </h2>
          <p>
            The JSE Limited has granted Fortress the listing of a further R1.3
            billion sustainability-linked notes, referencing the company's
            existing solar installation programme. Watch our latest solar
            installation
            <a
              href="https://www.youtube.com/watch?v=gXzSdgfPZ44"
              target="_blank"
              >here.</a
            >
          </p>
          <a
            class="clearLink"
            [routerLink]="['/foursight/press-release/article']"
            [queryParams]="{
              id: 'fortress-reit-issues-a-further-r13-billion-in-sustainability-linked-bonds-1'
            }"
            >Read more</a
          >
        </div>
      </div>
    </div>
    <div class="linkdinBlock">
      <a
        href="https://www.linkedin.com/company/fortressreitlimited/"
        target="_blank"
        ><h2>Follow us on <b>LinkedIn</b></h2></a
      >
    </div>
  </div>

  <div class="container showfour" *ngIf="showfive && !showfour">
    <img
      class="bannerImg"
      src="../../../../assets/images/foursight/foursight-edition-5-banner.webp"
      alt=""
    />
    <div class="row">
      <div class="white block">
        <div class="col">
          <h2>Fortress leads on exponential growth</h2>
          <p>
            Steven Brown, CEO of Fortress Real Estate, discusses the highlights
            from the group's Annual Results and details the positive progress
            being made in the retail and logistics portfolios.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{ id: 'fortress-leads-on-exponential-growth' }"
              >Read more</a
            >
            or
            <a
              class="clearLink"
              href="https://www.youtube.com/watch?v=CKeccU7eDjY"
              target="_blank"
              >Watch more</a
            >
          </p>
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=CKeccU7eDjY"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/article-1.webp);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=GOUSxP6iQmw"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/warehouse.webp);
            "
          ></div
        ></a>
        <div class="col">
          <h2>The Warehouse Advantage webinar</h2>
          <p>
            The Warehouse Advantage webinar, hosted at the end of September this
            year, highlighted the many opportunities that exist in the logistics
            real estate industry, including the future of logistics and all the
            dynamics at play. Our speakers discussed why logistics real estate
            is so explosive right now, the role warehousing plays, the longevity
            and state-of-the-art innovation required, and how building
            surrounding infrastructure is so important for the economy.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'he-warehouse-advantage-ecommerce-is-driving-implementation-of-new-strategies-in-logistics-and-warehousing'
              }"
              >Read more</a
            >
            or
            <a
              class="clearLink"
              href="https://www.youtube.com/watch?v=GOUSxP6iQmw"
              target="_blank"
              >Watch more</a
            >
          </p>
        </div>
      </div>
      <div class="white block">
        <div class="col">
          <h2>
            Is local lekker?<br />
            Local versus international<br />
            retail trends
          </h2>
          <p>
            Vuso Majija, Executive Director of Fortress Real Estate and vice
            president of the SA Council of Shopping Centres (SACSC), recently
            participated in a discussion hosted by the Broll Property Group on
            local versus international retail trends.
          </p>

          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'is-local-lekker-local-versus-international-retail-trends'
              }"
              >Read more</a
            >
            or
            <a
              href="https://www.youtube.com/watch?v=Fxfpx8P3x88"
              target="_blank"
              >Watch the Video Here</a
            >
          </p>
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=Fxfpx8P3x88"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/article-3-1.webp);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=Fxfpx8P3x88"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/pietermoolanwinner_thumbnail.webp);
            "
          ></div
        ></a>
        <div class="col">
          <h2>Pieter Moolman wins The Fortress Invitational</h2>
          <p>
            We'd like to congratulate Pieter Moolman on winning The Fortress
            Invitational golf tournament. He claimed his maiden victory on the
            Sunshine Tour when he beat rising star Jayden Schaper, who was
            playing his home course, in a playoff to win this inaugural
            tournament. This new tournament on the Sunshine Tour's 2022/2023
            schedule took place from 7 to 9 October 2022 at Ebotse Links in
            Johannesburg.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'pieter-moolman-wins-the-inaugural-fortress-invitational-golf-tournament'
              }"
              >Read more</a
            >
            about the tournament or
            <a
              href="https://www.youtube.com/watch?v=3EutRJoqELY"
              target="_blank"
              >watch the highlights video</a
            >
            of the 5-day event.
          </p>
        </div>
      </div>
    </div>
    <div class="linkdinBlock">
      <a
        href="https://www.linkedin.com/company/fortressreitlimited/"
        target="_blank"
        ><h2>Follow us on <b>LinkedIn</b></h2></a
      >
    </div>
  </div>

  <div class="container showSix" *ngIf="showSix">
    <img
      class="bannerImg"
      src="../../../../assets/images/foursight/foursight6/foursight_header.png"
      alt=""
    />
    <div class="row">
      <div class="white block">
        <div class="col">
          <h2>Fortress hits record R30 billion in direct real estate assets</h2>
          <p>
            Steven Brown, Fortress CEO summarises the group's Annual Results
            that were released at the beginning of September and shares an
            overview of the sustainability initiatives being implemented by the
            company.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'fortress-hits-record-r30-billion-in-direct-real-estate-assets'
              }"
              >Read more</a
            >
            or
            <a
              class="clearLink"
              href="https://www.youtube.com/watch?v=CKeccU7eDjY"
              target="_blank"
              >Watch more</a
            >
          </p>
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=33FhAYIG9Sc"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/foursight6/steven.jpg);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=GOUSxP6iQmw"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/foursight6/banner.png);
            "
          ></div
        ></a>
        <div class="col">
          <h2>Corporate SA spearheading efforts to achieve energy security</h2>
          <p>
            Fortress recently hosted the Power Priority event for clients,
            brokers and media where industry experts discussed the pivotal role
            the private sector is playing in addressing the energy crisis.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'private-sector-spearheading-efforts-to-achieve-energy-security'
              }"
              >Read more</a
            >
            or watch the
            <a
              href="https://www.youtube.com/watch?v=kfdrBg7Uik0"
              target="_blank"
              >First panel</a
            >,
            <a
              href="https://www.youtube.com/watch?v=kfdrBg7Uik0"
              target="_blank"
              >Second panel</a
            >
            or the
            <a
              href="https://www.youtube.com/watch?v=NukZ243EvZY"
              target="_blank"
              >Full video</a
            >
          </p>
        </div>
      </div>
      <div class="white block">
        <div class="col">
          <h2>
            Fortress wins SAPOA Award in the Industrial Development Category
          </h2>
          <p>
            The world-class Pick n Pay Distribution Centre at Eastport Logistics
            Park was awarded the Innovative Excellence Award in the Industrial
            Development category at this year's SAPOA Property Development
            Awards.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'fortress-wins-sapoa-award-in-industrial-development-category'
              }"
              >Read more</a
            >
            or
            <a
              href="https://www.youtube.com/watch?v=q5_nRYudKII"
              target="_blank"
              >Watch the Video</a
            >
          </p>
        </div>
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=Fxfpx8P3x88"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/foursight6/pnp.jpg);
            "
          ></div
        ></a>
      </div>
      <div class="blue block">
        <a
          class="colLink"
          href="https://www.youtube.com/watch?v=Fxfpx8P3x88"
          target="_blank"
          ><div
            class="col bgCol"
            style="
              background-image: url(../../../../assets/images/foursight/foursight6/gholf.jpg);
            "
          ></div
        ></a>
        <div class="col">
          <h2>
            Fortress Powers Growth in Sports: Two Exciting Sponsorships gain
            momentum
          </h2>
          <p>
            Fortress has proudly supported two significant sporting sponsorships
            this year. These partnerships reflect Fortress's commitment to
            nurturing talent and powering local athletes to reach new heights.
          </p>
          <p>
            <a
              class="clearLink"
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{ id: 'fortress-powers-growth-in-sports' }"
              >Read more</a
            >
            or watch to find out more about the
            <a
              href="https://www.youtube.com/watch?v=1J0YsdkQF9U"
              target="_blank"
              >Fortress Running Series</a
            >
            and the
            <a
              href="https://www.youtube.com/watch?v=KlBlvrSYEhs"
              target="_blank"
              >Fortress Invitational</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="linkdinBlock">
      <a
        href="https://www.linkedin.com/company/fortressreitlimited/"
        target="_blank"
        ><h2>Follow us on <b>LinkedIn</b></h2></a
      >
    </div>
  </div>

  <!-- this is the template to show newsletters where the news letters like like a page -->
  <div class="container" *ngIf="showSeven">
    <div class="showSeven">
      <img
        src="https://property.fortressfund.co.za/mailers/240610/images/header.jpg"
      />

      <div class="hero-text">
        <p class="pb-30">FOURsight Newsletter | Winter Edition</p>
        <h1 class="pb-20">
          Benefits of collaboration between retail property sector and local
          government
        </h1>
        <p class="pb-20">
          Fortress Retail recently hosted the Collective Impact event, bringing
          together key stakeholders to discuss collaborative strategies to
          combat urban decay, improve infrastructure and integrate new
          technologies for the benefit of communities surrounding shopping
          centres.
        </p>
        <p class="pb-30">
          The event, moderated by renowned journalist Jeremy Maggs, featured
          insights from prominent industry experts.
        </p>
        <div class="hero-btn">
          <a
            [routerLink]="['/foursight/press-release/article']"
            [queryParams]="{
              id: 'collaboration-between-the-retail-property-sector-and-local-government'
            }"
            >Read summary</a
          >
          <a target="_blank" href="https://youtu.be/5Ppnd2fpMw8">Watch video</a>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight7/Fortress_Retail_Collective_Impact_Steven_Brown.JPG"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>
            Logistics demand, disposals and capital recycling drives Fortress'
            operational performance
          </h2>
          <p class="p-15">
            Fortress Real Estate Investments Limited reported a strong
            operational update post-December 2023, highlighting robust demand
            for logistics space with 268,982m² developed, over 90% leased for 10
            years. CEO Steven Brown emphasised asset disposal for funding
            expansions continues.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'healthy-logistics-demand-disposals-of-non-core'
              }"
              >Read more</a
            >
            or the highlights in
            <a href="https://youtu.be/rbQy2zCOsRc" target="_blank">this video</a
            >.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight7/Sammar Logistics (3).JPG"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>Sammar Logistics partners with Fortress</h2>
          <p class="p-15">
            Sammar Logistics has taken occupation of its new 37,965m² warehouse
            on Pocket 2A at Clairwood Logistics Park in Kwa-Zulu Natal. This
            15-year lease agreement is underpinned by Sasol South Africa who
            identified Clairwood as the ideal location for the consolidation of
            their warehousing in the Durban South basin. This is one of the
            largest logistics deals to be concluded in the region.
          </p>
          <p>
            <a href="https://youtu.be/dHoas7VBNBg" target="_blank">Watch </a>

            the construction timelapse or read the article
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'sammar-logistics-partners-with-fortress-to-power-its-growth'
              }"
              >here</a
            >.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight7/Newsletter_article 4 (3).jpg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>Solar partnership at City Deep Industrial Park</h2>
          <p class="p-15">
            A new solar installation project at Fortress Real Estate's City Deep
            Industrial Park in Johannesburg has resulted in 394MWh of energy
            being produced since the system went online in November 2023.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'solar-partnership-powers-growth-at-city-deep-industrial-park'
              }"
              >Read More</a
            >
            about our partnership with Hudaco Energy.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight7/Cape_Epic_general_5.jpeg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>Fortress sponsors women's cycling team</h2>
          <p class="p-15">
            Fortress Real Estate proudly supports the Fortress Women Cycling
            Team-comprising Ila Stow, Laura Stark, and Kylie Hanekom-now known
            as Team Fortress. Balancing roles as cyclists and mothers, they
            recently competed in the Absa Cape Epic and 4Islands Croatia races,
            showcasing resilience and sportsmanship. Team Fortress looks forward
            to the Wine-to-Whales race.
          </p>
          <p>
            Read more
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'fortress-announces-sponsorship-of-women-cycling-team'
              }"
              >here</a
            >.
          </p>
        </div>
      </div>
      <div class="linkdinBlock">
        <a
          href="https://www.linkedin.com/company/fortressreitlimited/"
          target="_blank"
          ><h2>Follow us on <b>LinkedIn</b></h2></a
        >
      </div>
    </div>
  </div>

  <div class="container" *ngIf="showEight">
    <div class="showSeven">
      <img
        src="https://property.fortressfund.co.za/mailers/240610/images/header.jpg"
      />

      <div class="hero-text">
        <p class="pb-30">FOURsight Newsletter | Summer Edition</p>
        <h1 class="pb-20">Celebrating growth at Fortress</h1>
        <p class="pb-20">
          Welcome from Steven Brown, CEO of Fortress Real Estate
        </p>
        <p class="pb-30">
          The latest edition of FOURsight celebrates Fortress' successful year,
          highlighting how we have powered growth in our logistics, retail,
          sustainability initiatives and marketing. Key achievements from the
          team include strong retail turnover, property disposals, logistics
          developments, record-low vacancies, and increased solar capacity.
          Fortress' business strategy has delivered impressive results, and the
          team was interviewed for the November 2024 issue of Asset
          Magazine—check out the review
          <a target="_blank" href="https://assetmag.co.za/issue131/index.html?page=132">here</a>.
          For more details on the company’s annual results, visit
          <a
            [routerLink]="['/foursight/press-release/article']"
            [queryParams]="{
              id: 'financial-results-year-ended-30-june-2024'
            }"
          >here</a>.
        </p>
        <div style="position:relative; padding-bottom:56.25%; height:0; overflow:hidden; max-width:100%; width:100%;">
          <iframe src="https://www.youtube.com/embed/KVbHCTgjPWg" 
                  frameborder="0" 
                  style="position:absolute; top:0; left:0; width:100%; height:100%;" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
          </iframe>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight8/Cornubia Ridge Logistics Park (5).jpg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>
            Strong drivers supporting robust SA, CEE logistics real estate
            sectors
          </h2>
          <p class="p-15">
            Despite facing various challenges, South Africa's warehousing and
            logistics sector is delivering robust performance.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'strong-drivers-supporting-robust-logistics-real-estate-sectors'
              }"
              >Read more</a
            >
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight8/Nelspruit Plaza (4).jpg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>
            South Africa's economic outlook sparks renewed optimism in retail
          </h2>
          <p class="p-15">
            Following a challenging period for the commercial retail property
            market in South Africa, shifts in the country's economic outlook and
            improving consumer sentiment are buoying the local sector.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'south-africas-economic-outlook-sparks-renewed-optimism-in-retail'
              }"
              >Read More</a
            >.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight8/EvatonMallSolar.jpg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>
            Fortress makes significant strides towards decarbonisation and
            energy security targets
          </h2>
          <p class="p-15">
            Fortress continues to invest significantly in renewable energy and
            green technologies to achieve its ambitious energy security strategy
            and decarbonisation targets.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'fortress-significant-strides-towards-decarbonisation-energy-security-targets'
              }"
              >Read More</a
            >.
          </p>
        </div>
      </div>
      <div class="flex-container">
        <img
          src="../../../../assets/images/foursight/foursight8/RobinWilliams.jpg"
        />
        <span class="spacer"></span>
        <div class="align">
          <h2>
            Overjoyed Williams successfully defends Fortress Invitational title
          </h2>
          <p class="p-15">
            Congratulations to Robin Williams who successfully defended his
            Fortress Invitational title in October 2024.
          </p>
          <p>
            <a
              [routerLink]="['/foursight/press-release/article']"
              [queryParams]="{
                id: 'williams-defends-fortress-invitational-title'
              }"
              >Read more</a
            >.
          </p>
        </div>
      </div>
      <div class="linkdinBlock">
        <a
          href="https://www.linkedin.com/company/fortressreitlimited/"
          target="_blank"
          ><h2>Follow us on <b>LinkedIn</b></h2></a
        >
      </div>
    </div>
  </div>
</div>
